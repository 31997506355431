@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@300&display=swap');

.App {
  text-align: center;
  background-color: #F5FFFB;
  height: 100vh
}

main {
  background-color: #F5FFFB;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Feed a {
  display: inline-block;
  margin: 1% 1%;
  width: 100%;
  height: 100%;
  line-height: 0;
}

.Feed a img {
  width: 100%
}

.footer{
  flex-shrink: 0;
  padding: 1%;
  text-align: center;
  background-color: #ece3dd;
  text-decoration: none;
}

.ig{
  text-decoration: none;
}
.fb{
  text-decoration: none;
}

.heroDiv  {
  height: 29%;
  width: 100%;
}

@media only screen and (min-width: 700px) {
  .heroDiv  {
    height: 50%;
    width: 100%;
  }
}

.heroImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: 50% 60%;
  width: 625px;
}

@media only screen and (max-width: 600px) {
  .heroImg {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 250px;
  }
}


@media only screen and (max-width: 600px) {
  .Feed a {
    display: inline-block;
    margin: 1% 1%;
    width: 100%;
    height: 100%;
    line-height: 0;
  }
}

